import { useEffect, useState } from 'react';

function GetData(loaded_data) {

    const [all_downloads, setAllDownloads] = useState([]);

    useEffect(() => {
        if ( loaded_data === null  ) {
            const host = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : '';
            const url = `${host}/api/downloads`;

            const fetchData = async () => {
                try {
                    
                    const response = await fetch(url);
                    const api_data = await response.json();
                    
                    let downloads = []
                    for (let i = 0; i < api_data.length; i++){
                        downloads.push({'title' : api_data[i].title, 'urlSlug' : api_data[i].slug, 'downloadLink' : api_data[i].download_link})
                    }

                    setAllDownloads(downloads)

                } catch (error) {
                    console.log("error", error);
                }

            };
    
            fetchData();
        } else {
            setAllDownloads(loaded_data.downloads)
        }

    }, [loaded_data]);
    return { all_downloads };
}

export default GetData;