import React from 'react';
import "./Error.css";
 
function Loading() {
   return(
       <div className="Error_box">
           <h2>Loading...</h2>
       </div>
   )
}
 
export default Loading;