import React from 'react';
import { Helmet } from 'react-helmet';
import { useForm, ValidationError } from '@formspree/react';
import SurveyFooter from "../components/universal/SurveyFooter/SurveyFooter";
import "./Contact.css"
import ReCaptchaV2 from 'react-google-recaptcha'
import hero_image from "../images/components/Hero/contact_hero_image.webp";

function ContactForm() {
  const [state, handleSubmit] = useForm("mrgjeydz");
  if (state.succeeded) {
      return <div className="success-message"><div className='success-text'><h3>Thank you!</h3><p>We'll be in touch soon.</p></div></div>;
  }
  return (
      <div className="contact-form">

        <Helmet>
            <title>Mission Encodeable Contact information</title>
            <meta name="description" content="If you have a question, or would like to find out more about Mission Encodeable, then you can get in touch and we'll get back to you."/>
        </Helmet>


      <form onSubmit={handleSubmit}>

        <label htmlFor="name">Name</label>

        <input id="name" type="text" name="name" required/>
        <ValidationError prefix="Name" field="name" errors={state.errors}/>

        <label htmlFor="email">Email Address</label>

        <input id="email" type="email" name="email" required/>

        <ValidationError prefix="Email" field="email" errors={state.errors}/>

        <label htmlFor="message">Message</label>

        <textarea id="message" name="message" required/>

        <ValidationError prefix="Message" field="message" errors={state.errors}/>

        <ReCaptchaV2 className="recaptcha_box" sitekey={`6LeD9-ceAAAAAH3ha0C462iYKaTDr3vM7P-nxaYO`} />
        
        <button className="btn_orange_fill" type="submit" disabled={state.submitting}>
            Submit
        </button>
      </form>
    </div>

  );
}

function Contact(){
    return(
        <div className='contact_page'>

            <section className="contact_hero">
                    <div className='text'>
                      <h1>Have you got a question about Mission Encodeable?</h1>
                    </div>
                    <div className='hero_image_wrapper'>
                      <img className="hero_img" src={hero_image} alt="A red telephone box"/>
                    </div>
            </section>

            <section className='form'>

              <div className='form_text'>
                <h3>Get in touch</h3>
                <p>If you have a question, or would like to find out more about Mission Encodeable, then fill in the form and we'll get back to you.</p>
                <p>If you'd rather send us a quick email, send it to: <a href="mailto:info@missionencodeable.com">info@missionencodeable.com</a> and we'll be in touch.</p>
                <p>If you've spotted an issue with one of our courses then please fill in our <a href="https://rebrand.ly/mission-encodeable-error-report-form" target="_blank" rel="noreferrer">error report form</a> so that we can fix it.</p>
              </div>

              <div className='form_wrapper'>
                <ContactForm/>
              </div>

            </section>
            <SurveyFooter/>

        </div>
    )
}

export default Contact;

