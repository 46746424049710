import { Helmet } from 'react-helmet';

import about_hero from "../images/components/Hero/motherboard_hero_image.webp";
import BenefitsCard from '../components/universal/BenefitsCard/BenefitsCard';
import SurveyFooter from "../components/universal/SurveyFooter/SurveyFooter";
import reduce_costs_icon from "../images/universal/icons/reduce_costs_icon.svg";
import uplevel_engagement_icon from "../images/universal/icons/uplevel_icon.svg"
import save_time_icon from "../images/universal/icons/save_time_icon.svg"
import quotemarks from "../images/universal/icons/quotemarks_icon.svg";
import team_image from "../images/pages/about/team_photo.webp";
import our_mission_image from "../images/pages/about/tower_bridge_image.webp";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import "swiper/css/navigation";

import "./About.css";

function About() {
    return (
        <div>
			<Helmet>
				<title>About Mission Encodeable | Inspiring the next generation of computer scientists</title>
				<meta name="description" content="Mission Encodeable offers free courses in coding, design and digital making for young people across the world." />
			</Helmet>

			<div className="powder-blue-bg">
				<section className='about-hero'>
					<div className='text'>
						<h1 class="darkgreen"><em>Inspiring</em> the next generation of computer scientists</h1>
						<p class="darkgreen">Mission Encodeable was founded by Harry and Anna Wake, two teenagers looking to transform the way young people learn to code.</p>
					</div>

					<div className="hero-image-wrapper">
						<img src={about_hero} className="hero-img" alt="A close up of a computer's motherboard" />
					</div>
				</section>

				<section className="about-values">
					<p className="mini-section-heading mini-secton-heading-centred">The benefits</p>
					<h3 className='darkgreen engaging-tutorials-benefit-padded'>Engage your students with our free online <u>tutorials</u></h3>
					<p className="darkgreen">Our tutorials take a creative, project-based approach.</p>

					<div className="values-wrapper">
						<BenefitsCard
							benefitIcon={save_time_icon}
							benefitTitle="Save time"
							benefitDescription="Free up your planning time and take control of your workload."
						/>
						<BenefitsCard
							benefitIcon={uplevel_engagement_icon}
							benefitTitle="Uplevel engagement"
							benefitDescription="Mission Encodeable offers a focus on practical coding exercises and projects."
						/>
						<BenefitsCard
							benefitIcon={reduce_costs_icon}
							benefitTitle="Reduce costs"
							benefitDescription="Our resources are all freely available, so there's no need to worry about budget."
						/>
					</div>
				</section>
			</div>

			<section className="team">
				<div className="team-text">
					<h2 className="darkgreen">The <u>team</u></h2>
					<p>Yep, that's us! We're Harry and Anna, the creators of Mission Encodeable.</p>
					<p>We're two students passionate about coding, engineering and design. We decided to create Mission Encodeable as a project to share our passion with others.</p>
					<p>When we're not doing our homework, we're busy writing tutorials, designing website wireframes and planning the next courses for Mission Encodeable.</p>
				</div>
				
				<img src={team_image} alt="Harry and Anna Wake, the founders of Mission Encodeable, in front of a colourful Thierry Noir wall graffiti artwork"/>
			</section>

			<section className="our-mission">
				<div className="our-mission-text">
					<h2 className="darkgreen">Our <u>Mission</u></h2>
					<p>We're on a mission to inspire the next generation of computer scientists. </p>
					<p>We're creating online coding and design tutorials that are fun and engaging to students across the globe. Users will uplevel on our platform, and earn certificates. By doing so, they’ll improve their programming and logic skills.</p>
					<p>Ultimately, we want to transform coding education across the globe, so that everyone can learn digital skills in an engaging online environment.</p>
					<p>Join us on our adventure!</p>
				</div>

				<img src={our_mission_image} alt="Tower Bridge in London"/>
			</section>

			<section className='quote'>
				<Swiper
					spaceBetween={20}
					slidesPerView={1}
					navigation={true} 
					modules={[Navigation]}
					className='mySwiper'
					autoplay={{
					delay: 3000,
					disableOnInteraction: true,
					}}
				>
					<SwiperSlide>
						<div className="quote-text">
							<img src={quotemarks} className="quotemark" alt="quotemark"/>
							<h3>“Mission Encodeable is a great way of learning to code through engaging step by step projects. Each level is really well presented and guides you through making a creative and fun program.  I'll definitely be using some of their ideas in my lessons!”</h3>
							<h4>Andy Colley, Director of Computing, Laurus Cheadle Hulme</h4>
						</div>
					</SwiperSlide>

					<SwiperSlide>
						<div className="quote-text">
							<img src={quotemarks} className="quotemark" alt="quotemark"/>
							<h3>“Mission Encodeable is a great initiative led by students for students, it conveys a passion for programming which hopefully through engagement will prove to be contagious.”</h3>
							<h4>Tim Brady, Subject advisor for Computer Science and IT, Pearson Edexcel</h4>
						</div>
					</SwiperSlide>

					<SwiperSlide>
						<div className="quote-text">
							<img src={quotemarks} className="quotemark" alt="quotemark"/>
							<h3>“The very best way to become a good programmer is to write programs! Mission Encodeable is a great platform for enriching your journey learning to code.”</h3>
							<h4>Craig Sargent & David Hillyard aka Craig 'n' Dave </h4>
						</div>
					</SwiperSlide>

					<SwiperSlide>
						<div className="quote-text">
							<img src={quotemarks} className="quotemark" alt="quotemark"/>
							<h3>“Mission Encodeable is a great introduction to the Python programming language. With clear instructions and a well thought out progression, this responsive website is a wonderful place to begin your journey into programming."</h3>
							<h4>Marc Scott, Senior Learning Manager, Raspberry Pi Foundation</h4>
						</div>
					</SwiperSlide>

					<SwiperSlide>        
						<div className="quote-text">
							<img src={quotemarks} className="quotemark" alt="quotemark"/>
							<h3>“Mission Encodeable is perfect for anyone looking for a simple, engaging and well structured introduction to programming in Python.”</h3>
							<h4>Mr Dring, Head of Computing, Fulford School</h4>
						</div>
					</SwiperSlide>

					<SwiperSlide>
						<div className="quote-text">
							<img src={quotemarks} className="quotemark" alt="quotemark"/>
							<h3>“Mission Encodeable has been used to engage our students in an after-school coding club, and it's been a massive success! I will be looking to incorporate it in our KS3 programming schemes of work too! Well done Harry and Anna!”</h3>
							<h4>Mrs Coote, Teacher of Computing, Marple Hall School</h4>
						</div>
					</SwiperSlide>

					<SwiperSlide>
						<div className="quote-text">
							<img src={quotemarks} className="quotemark" alt="quotemark"/>
							<h3>“I'm just going to give them the website. 6th grade Python is done. Thanks, Harry and Anna.”</h3>
							<h4>Kelly Schuster-Paredes, co-host of Teaching Python podcast and Computer Science Teacher, Pine Crest School</h4>
						</div>
					</SwiperSlide>

					<SwiperSlide>        
						<div className="quote-text">
							<img src={quotemarks} className="quotemark" alt="quotemark"/>
							<h3>“I liked Mission Encodeable because it gave me a refresher about coding basics. It completely refreshed my memory about Python.”</h3>
							<h4>Student</h4>
						</div>
					</SwiperSlide>

					<SwiperSlide>
						<div className="quote-text">
							<img src={quotemarks} className="quotemark" alt="quotemark"/>
							<h3>“A refreshingly different way to look at the topic... very fun!”</h3>
							<h4>Student</h4>
						</div>
					</SwiperSlide>
					<SwiperSlide>
						<div className="quote-text">
							<img src={quotemarks} className="quotemark" alt="quotemark"/>
							<h3>“Great program, easy learning and fun for everyone willing to learn about design and programming!”</h3>
							<h4>Student</h4>
						</div>
					</SwiperSlide>
				</Swiper>
			</section>

			<SurveyFooter/>
    	</div>
    );
};

export default About;