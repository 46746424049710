import "./BlogCard.css"
import { useNavigate, Link } from "react-router-dom";


function BlogCard(props){
    let navigate = useNavigate();

    return(

        <div className="card-wrapper-column">

            <div className="blog-card">
                <Link to={`/blog/${props.blogSlug}`}>

                    <div className="card-featured-top-section">
                        <img alt={props.imageAltText} src={`../../../../../blog_images/blog-post-${props.blogSlug}-thumbnail-image.webp`} loading="lazy"/>
                    </div>

                    <div className="card-info-bottom-section">

                        <div className="blog-tag"><p className="blog-tag-name">BLOG</p></div>

                        <p className="blog-date">{props.blogDate}</p>

                        <h4 className="blog-title">{props.blogTitle}</h4>

                        <button onClick={() => navigate(`/blog/${props.blogSlug}`)} className="btn-link"><span className="btn-label">Read article</span></button>

                    </div>
                </Link>
            </div>
        </div>
    );
}


export default BlogCard;
