import {useEffect, useState} from "react";

function GetBlogPost(post_slug) {
    const [title, setTitle] = useState([]);
    const [content, setContent] = useState([]);
    const [date, setDate] = useState([]);
    const [author, setAuthor] = useState([]);
    const [meta_description, setMetaDescription] = useState([]);
    const [alt_text, setAltText] = useState([]);
    useEffect(() => {
        const host = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : '';
        const url = `${host}/api/blog/${post_slug}`;
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                setContent(json[0].content_html)
                setTitle(json[0].title)
                setDate(json[0].date)
                setAuthor(json[0].author)
                setMetaDescription(json[0].meta_description)
                setAltText(json[0].image_alt_text)
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    })
    return { title, content, date, author, meta_description, alt_text };
}
export default GetBlogPost;


