import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const CustomForm = ({ status, message, onValidated }) => {

  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      MERGE0: email.value,
    });


  return (
    <div>

      {status !== "success" ? (

        <div className="newsletter-subscribe-form-components-wrapper">

        <div className="subscribe-input-field">
          <label htmlFor="email">Your email</label>
          <input
            ref={node => (email = node)}
            type="email"
            id="email"
            placeholder="Email address"
          />
        </div>

        <button className="btn_orange_fill" onClick={submit}>Submit</button>
        </div>
          
        ) : null}
      
      {status === "sending" && <p className="subscribe-status-message">Sending...</p>}
      {status === "error" && (<p className="subscribe-status-message">Sorry, there has been an error. Please try again.</p>)}
      {status === "success" && (<p className="subscribe-status-message">Success, thank you for subscribing to our newsletter.</p>)}

    </div>
  );
};

function subscribeForm(){
    const url = "https://gmail.us21.list-manage.com/subscribe/post?u=083844e417cbf80cb397af306&id=2bc9368b46";
    return (
      <div>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        />
      </div>
    );
}

export default subscribeForm;