import React from "react";

function ProjectSection(props) {
    if (props.project_name === null) {
        return null;
    } else {
        return (
            <div className={props.bottom ? "project project-bottom" : "project"}>
                <img src={props.project_icon} alt="Project icon" />
                <div className="project-name-wrapper">
                    <p className="project-name">{props.project_name}</p>
                </div>
            </div>
        )
    }

}

export default ProjectSection;
