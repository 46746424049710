import "./LevelCard.css"
import { useNavigate, Link } from "react-router-dom";

import capstone_project_icon from "../../../images/universal/icons/capstone_project_icon.svg";
import challenge_project_icon from "../../../images/universal/icons/legacy_challenge_project_icon.svg";
import ProjectSection from "./ProjectSection";

function LevelCard(props) {
    let navigate = useNavigate();
    return (
        <div className="card-wrapper-column">
            <div className="level-card">
                <Link to={`/${props.course_type}/level/${props.levelNumber}`}>
                    <div className="card-featured-top-section">
                        <h3>Level {props.levelNumber}</h3>
                    </div>
                    <div className="card-info-bottom-section">
                        <div className="language-tag"><p className="language-tag-name">PYTHON</p></div>
                        <p className="level-sentence">{props.levelDescription}</p>

                        <ProjectSection 
                            project_icon={capstone_project_icon}
                            project_name={props.project_one}
                            bottom={ props.project_two === null}
                        />
                        <ProjectSection 
                            project_icon={challenge_project_icon}
                            project_name={props.project_two}
                            bottom={true}
                        />
                        
                        {/* <div className="project project-bottom">
                            <img src={capstone_project_icon} alt="Capstone project icon" />
                            <div className="project-name-wrapper">
                                <p className="project-name">{props.project_one}</p>
                            </div>
                        </div> */}


                        <button onClick={() => navigate(`/${props.course_type}/level/${props.levelNumber}`)} className="btn-link"><span className="btn-label">Start level</span></button>

                    </div>
                </Link>
            </div>
        </div>
    );
}


export default LevelCard;