import React from "react";

function Project(props) {
    if (props.title === null) {
        return null;
    } else {
        return (
            <div className="project">
                <div className="project_text">
                    <h3>{props.title}</h3>
                    <p>{props.description}</p>
                </div>
                <div className="replit_embed">
                    <iframe frameBorder="0" width="90%" height="300px" src={props.replit_link}></iframe>
                </div>
            </div>
        )
    }
}

export default Project;