import React from "react";
import { useNavigate } from "react-router-dom";

import './SummerProject.css';

function SummerProject(props) {
    let navigate = useNavigate();
    return(
        <div className={`summer-project`}>
            <img src={`../../../../summer_images/summer-project-${props.project_slug}.svg`} className="summer-project-image"></img>
            <div className="summer-project-card-content">
                <div className="summer-project-title">
                    <h4>Level {props.level_number}</h4>
                    <h3>{props.project_name}</h3>
                </div>
                <button onClick={() => navigate(`/summer/python/level/${props.level_number}`)} className={`summer-project-btn`}>Start project</button>
                <a className="a-no-decoration" href={`/python/level/${props.level_number}`} target="_blank" rel="noreferrer"><p className={`start-me-level-link-summer-blue`}>Visit Mission Encodeable Level {props.level_number}</p></a>
            </div>
        </div>
    )
}

export default SummerProject;