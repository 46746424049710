import React, {useEffect, useState} from "react";
import { useParams, useNavigate } from "react-router-dom";

import GetSeasonalProject from "../hooks/getSeasonalProject";

import MailchimpFormContainer from "../components/universal/NewsletterFooter/MailchimpFormContainer";

import christmas_header_4 from "../images/pages/christmas/christmas_header_4.svg";
import christmas_header_3 from "../images/pages/christmas/christmas_header_3.svg";
import christmas_header_2 from "../images/pages/christmas/christmas_header_2.svg";
import christmas_header_1 from "../images/pages/christmas/christmas_header_1.svg";
import christmas_me_tag from "../images/pages/christmas/christmas_me_tag.svg";
import christmas_delivery_van from "../images/pages/christmas/christmas_delivery_van.svg";
import twitter from "../images/pages/christmas/christmas_twitter_logo.svg";
import linkedin from "../images/pages/christmas/christmas_linkedin_logo.svg";
import facebook from "../images/pages/christmas/christmas_facebook_logo.svg";

// import these SVGs as ReactComponents so that we can change their colour
import { ReactComponent as PlusIcon } from '../images/universal/icons/plus_icon.svg';
import { ReactComponent as MinusIcon } from '../images/universal/icons/minus_icon.svg';

import "./ChristmasProjectPage.css";
import ReactHtmlParser from 'html-react-parser';

import HighlightCode from "../HighlightCode";
 
const DOMPurify = require('dompurify');

function ChristmasProjectPage() {
    let params = useParams();
    let navigate = useNavigate();
    let level_number = params.levelNumber;
    let { title, content_html, colour } = GetSeasonalProject(level_number, 'christmas');
    let clean_project_content = DOMPurify.sanitize(content_html, { ADD_TAGS: ["iframe"], ADD_ATTR: ['target', 'allow', 'allowfullscreen', 'frameborder', 'scrolling'] });
    
    useEffect(() => {
        HighlightCode();
    })

    const parsedHTML = ReactHtmlParser(clean_project_content);
    let currentAccordionSection = 0;



    return (
        <div className="christmas-home-bg">
            <div className="christmas-header">
                <img className="christmas-header-1" src={christmas_header_1} alt="A christmas header with snowflakes"></img>
                <img className="christmas-header-2"src={christmas_header_2} alt="A christmas header with snowflakes"></img>
                <img className="christmas-header-3"src={christmas_header_3} alt="A christmas header with snowflakes"></img>
                <img className="christmas-header-4"src={christmas_header_4} alt="A christmas header with snowflakes"></img>
                <a href="/" target="_blank"><img src={christmas_me_tag} className="present-tag" alt="A present tag that links to Mission Encodeable"></img></a>
            </div>

            <section className={`christmas-project-top-about christmas-project-top-about-${colour}`}>
                <div className={`christmas-project-top-about-text christmas-project-top-about-text-${colour}`}>
                    <button className={`christmas-project-page-btn christmas-project-page-btn-${colour}`} onClick={() => navigate(`/christmas`)}>← Back to the list of projects</button>
                    <h3>Python Level {level_number}</h3>
                    <h2>{title}</h2>
                    <a className="a-no-decoration" href={`/python/level/${level_number}`} target="_blank" rel="noreferrer"><p className={`start-me-level-link-${colour}`}>Visit Mission Encodeable Level {level_number}</p></a>
                </div>
            </section>

            <section className={`christmas-project-content christmas-project-content-${colour}`}>
                {parsedHTML.map((child, index) => {
                if (child.props && child.props.className === 'accordion-section') {
                    currentAccordionSection = currentAccordionSection + 1
                    return <AccordionSection key={index} sectionNumber={currentAccordionSection} colour={colour}>{child}</AccordionSection>;
                }
                return child;
                })}
            </section>

            <section>
                <img src={christmas_delivery_van} className="christmas-newsletter-van" alt="A graphic of a Christmas delivery van"></img>
                <div className="christmas-newsletter">
                    <h2>Teachers: Sign up to our coding newsletter</h2> 
                    <MailchimpFormContainer/>
                </div>
            </section>

            <footer>
                <div className='second-row christmas-footer-second-row'>
                        <p className='white'>© Mission Encodeable 2023</p>
                        <div className="social-footer-icons">
                            <a href="https://www.linkedin.com/company/mission-encodeable/" target="_blank" rel="noreferrer" className="social-link"><img src={linkedin} alt="LinkedIn icon"/></a>
                            <a href="https://twitter.com/missionencode" target="_blank" rel="noreferrer" className="social-link"><img src={twitter} alt="Twitter icon"/></a>
                            <a href="https://www.facebook.com/people/Mission-Encodeable/100094369925208/" target="_blank" rel="noreferrer" className="social-link"><img src={facebook} alt="Facebook icon"/></a>
                        </div>
                </div>
            </footer>
        </div>
    )

}

function AccordionSection({children, sectionNumber, colour}) {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleSection = () => {
      setIsOpen(!isOpen);
    };

    useEffect(() => {
        HighlightCode();
    })

    let header;
    let content = [];

    // Iterate through children to separate header and content
    let allContent = children.props.children;
    for (let child of allContent) {
        if (child.type === 'p') {
            header = child;
        } else {
            content.push(child);
        };
    };
  
    return (
      <div className="accordion-section">
        <div className={`accordion-header accordion-header-${colour}`} onClick={toggleSection} style={{ cursor: 'pointer' }}>
            {header}
            {isOpen ?  <MinusIcon className={`accordion-image-christmas accordion-image`}/> : <PlusIcon className={`accordion-image-christmas accordion-image`}/>}
        </div>
        {isOpen && (
          <div className="accordion-foldout">
            {content} {/* Render content within the accordion section */}
          </div>
        )}
      </div>

    );
}

export default ChristmasProjectPage;