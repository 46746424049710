import React from "react";
import { useNavigate } from "react-router-dom";

import './ChristmasProject.css';

function ChristmasProject(props) {
    let navigate = useNavigate();
    return(
        <div className={`christmas-project christmas-project-${props.colour}`}>
            <img src={`../../../../christmas_images/christmas-project-${props.project_slug}.svg`} className="christmas-project-image"></img>
            <div className="christmas-project-card-content">
                <div className="christmas-project-title">
                    <h4>Level {props.level_number}</h4>
                    <h3>{props.project_name}</h3>
                </div>
                <button onClick={() => navigate(`/christmas/python/level/${props.level_number}`)} className={`christmas-project-btn christmas-project-btn-${props.colour}`}>Start project</button>
                <a className="a-no-decoration" href={`/python/level/${props.level_number}`} target="_blank" rel="noreferrer"><p className={`start-me-level-link-${props.colour}`}>Visit Mission Encodeable Level {props.level_number}</p></a>
            </div>
        </div>
    )
}

export default ChristmasProject;