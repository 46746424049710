import React from 'react';
// COMMENTED OUT FOR SURVEY BANNER
// import React, { useState } from "react";
import { Helmet } from 'react-helmet';
// COMMENTED OUT FOR SURVEY BANNER
// import { ReactComponent as CloseMenu } from "../images/components/Navbar/x.svg";
// import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import SwiperCore, { Navigation } from 'swiper';

import LevelCard from "../components/pages/get_started/LevelCard.js";
import "./GetStarted.css";
import SurveyFooter from "../components/universal/SurveyFooter/SurveyFooter.js";

import GetAllLevelInfo from "../hooks/getAllLevelInfo.js";
import Loading from "./Loading";
import Error from "./Error";

// COMMENTED OUT FOR CHRISTMAS
// import christmas_snowman from "../images/pages/christmas/christmas_snowman.svg";

SwiperCore.use([Navigation]);

function GetStarted(props){

    var course;

    if (props.legacy) {
        course = "python-legacy";
    } else {
        course = "python";
    }

    // COMMENTED OUT FOR SURVEY BANNER
    // const [showNewsBanner, setShowNewsBanner] = useState(true);
    // const dismissNewsBanner = () => setShowNewsBanner(false);

    let { all_level_card_information, loading } = GetAllLevelInfo(course);
    var error = false;
 
    if (loading === false && all_level_card_information.length === 0) {
        error = true; 
    }
    
    if (loading) {
        return <Loading />
    } else if (error) {
        return <Error />
    } else {
    
        return(
            <div>

            <Helmet>
                <title>Tutorials | Get started with Mission Encodeable</title>
                <meta name="description" content="Choose a coding tutorial, and get started with Mission Encodeable. Each level contains interactive coding exercises, quizzes and projects." />
            </Helmet>

            <div className="get-started-page-contents">

            <section className="get-started-header">
                    <h1>Get started</h1>
                    <p>{props.legacy ? "This is an archive of our legacy tutorials, and will be available to view until September 2024 only. If starting Mission Encodeable's courses, we strongly recommend using the new tutorials." : "Begin your coding journey."}</p>
                </section>

                {/* COMMENTED OUT FOR SURVEY BANNER */}
                {/* <div className="news-banner-wrapper">
                    {showNewsBanner ? 
                        <div className="news-banner-content">
                            <p className="news-banner-text">NEW: Mission Encodeable has changed. View our {props.legacy ? "new" : "old"} tutorials here: <Link className="news-banner-text" to={props.legacy ? "/get-started" : "/get-started-legacy"}>{props.legacy ? "New" : "Legacy"} tutorials</Link></p>
                            <CloseMenu className="dismiss-news-banner" onClick={dismissNewsBanner}/>
                    </div> : <div></div>
                    }
                </div> */}

                <section className='python_tutorials' id="python_tutorials">

                    <h3 className="python-tutorials-subheading">Python Tutorials</h3>

                    <div className="get-started-swiper-container">
                        <Swiper
                            slidesPerView={1}
                            navigation
                            spaceBetween={10}
                            pagination={{
                            clickable: true,
                            renderBullet: function(index, className) {
                                return '<span class="' + className + '"><img class="pagination-bullet"/></span>';
                            }
                            }}
                            breakpoints={{
                                485: {
                                    slidesPerView: 1.5,
                                    spaceBetween: 20,
                                },
                                675: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                875: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                                },
                                1040: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                                },
                                1230: {
                                    slidesPerView: 3.8,
                                    spaceBetween: 20,
                                },
                                1900: {
                                    slidesPerView: 5,
                                    spaceBetween: 20,
                                },
                            }}
                            modules={[Pagination]}
                            className="levelCardSwiper"
                        
                        >
                            {all_level_card_information.map((card, index) => 
                                <SwiperSlide key={index}>
                                    <LevelCard
                                        course_type={course}
                                        levelNumber={index + 1}
                                        levelDescription={card.level_description}
                                        project_one={card.class_project}
                                        project_two={card.challenge_project}
                                    />
                                </SwiperSlide>
                            )}
                            
                        </Swiper>
                    </div>

                
                </section>

                {/* CHRISTMAS PROMO BLOCK - MUST DELETE AFTER CHRISTMAS! */}
                                
                {/* <section className="christmas-ad-section">
                    <div className="christmas-ad-block">
                        <div>
                            <h6>Looking for more Python fun?</h6>
                            <h1>Check out our Christmas coding challenges!</h1>
                            <Link to={'/christmas'}><button className="btn-christmas-yellow-fill" >See the Challenges</button></Link>
                        </div>
                        <img src={christmas_snowman} alt="A Christmas snowman, surrounded with snowflakes."/>
                    </div>
                </section> */}
                <SurveyFooter/>
            </div>
            </div>
        )
    }
}

export default GetStarted;