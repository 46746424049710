import React, {useState} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import './Introduction.css'
import GetCourseInfo from "../hooks/getCourseInfo";
 
import Loading from './Loading.js';
import Error from "./Error.js";
import VideoModal from "../components/universal/VideoModal/VideoModal.js";
import Project from "../components/pages/introduction/Project.js";
 
const parse = require('html-react-parser');
const DOMPurify = require('dompurify');
 
function Introduction() {
   let navigate = useNavigate();
   let params = useParams();
   const [showVideo, setShowVideo] = useState(false);
   let level_number = params.levelNumber;
   let course = params.course;
   let { class_project_title, challenge_project_title, level_topics, class_project_description, challenge_project_description, class_project_replit_link, challenge_project_replit_link, level_description, video_link, loading} = GetCourseInfo(course, level_number);
   let clean_level_topics = DOMPurify.sanitize(level_topics, {ALLOWED_ATTR: ['style', 'class', 'className'],});
   var error = false;
 
   if (loading === false && level_description.length === 0) {
       error = true; 
   }
 
   if (loading) {
       return <Loading />
   } else if (error) {
       return <Error />
   } else {
       return (
           <div>
               <section>
                   <div className="wrapper">
                       <div className="top-section">
                           <h1>{course === "python" ? "Python" : "Python Legacy"} Level {level_number}</h1>
                           <h3>{level_description}</h3>
                       </div>
                      
                       <div className="float-wrapper">
                           <div className="float-or-inline-if-narrow">
                                <div className="top-section-image-and-video">
                                    <div className="introduction-card-featured-top-section topics-box-top-section">
                                        {video_link === null ? <h3>Level {level_number}</h3> : <img src={`../../introduction_thumbnails/${course}-level-${level_number}-video-thumbnail.webp`} className='level_topics_image' onClick={() => setShowVideo(true)}></img>}
                                    </div>
                                </div>
                               <div className='level_topics_info'>
                                    <button onClick={() => {navigate(`/${course}/level/${level_number}/step/1`);}} className="btn_orange_fill high">Start level</button>
                                    <h4>What you'll learn</h4>
                                    <div className="level_topics_wrapper">{parse(`${clean_level_topics}`)}</div>
                                    <button onClick={() => {navigate(`/${course}/level/${level_number}/step/1`);}} className="btn_orange_fill low">Start level</button>
                               </div>
                           </div>
                       </div>
  
                       <div className="bottom-section">
                           <h2>{course === "python" ? "Capstone Project" : "The projects"}</h2>
                           <div className='projects'>
                                <Project title={class_project_title} description={class_project_description} replit_link={class_project_replit_link}/>
                                <Project title={challenge_project_title} description={challenge_project_description} replit_link={challenge_project_replit_link}/> 
                           </div>
                       </div>
  
                   </div>
  
                   <div className="start-section">
                       <h2>Ready to start Python level {level_number}?</h2>
                       <button onClick={() => {navigate(`/${course}/level/${level_number}/step/1`);}} className="btn_green_outline">Start level</button>
                   </div>
               </section>
               <VideoModal onClose={() => setShowVideo(false)} showVideo={showVideo} videoLink={video_link}/>
           </div>
       )
   }
}
 
export default Introduction;