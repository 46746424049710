import React from "react";
import './GeneralResource.css'

import pdf_icon from '../../../images/universal/icons/pdf_icon.svg';
import xlsx_icon from '../../../images/universal/icons/xlsx_icon.svg';
import ppt_icon from '../../../images/universal/icons/ppt_icon.svg';
import py_icon from '../../../images/universal/icons/code_icon.svg';

function GeneralResourceCard(props) {
    let file_type_icon;
    if (props.file_type === "PDF") {
        file_type_icon = pdf_icon;
    } else if (props.file_type === "XLS") {
        file_type_icon = xlsx_icon;
    } else if (props.file_type === "PPT") {
        file_type_icon = ppt_icon;
    } else if (props.file_type === "PY") {
        file_type_icon = py_icon;
    } else {
        file_type_icon = pdf_icon;
    }

    return (
        <div className="teacher-resource-card">
            
            <img src={file_type_icon} className="file-type-icon"></img>
            <div className="teacher-resource-info">
                <div>
                    <h4 className="darkgreen resource-name">{props.name}</h4>
                    <p className="resource-info">Published {props.date_published} | {props.file_type} | {props.file_size}</p>
                    <p className="green">{props.description}</p>
                </div>
                <p className="green teacher-resource-card-link"><a href={props.download_link} target="_blank" rel="noreferrer">{props.link_action_type}</a></p>
            </div>
        </div>
    )
}


export default GeneralResourceCard;