import { useEffect, useState } from 'react';
 
function GetData(any_data, level_number, course) {
   const [all_steps, setAllSteps] = useState([]);
   const [all_titles, setAllTitles] = useState([]);
   const [loading, setLoading] = useState(true);
   useEffect(() => {
       if ( any_data === null  ) {
           const host = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : '';
           const url = `${host}/api/${course}/level/${level_number}`;
 
           const fetchData = async () => {
               try {
               const response = await fetch(url);
               const json = await response.json();
               let titles = []
               let steps = []
               for (let i = 0; i < json.length; i++){
                   titles.push(json[i].title);
                   steps.push(json[i].content_html)
               }
               setAllTitles(titles)
               setAllSteps(steps)
               setLoading(false);
               } catch (error) {
                   console.log("error", error);
               }
           };
  
           fetchData();
       } else {
           setAllSteps(any_data.courseSteps);
           setAllTitles(any_data.courseTitles);
           setLoading(false);
       }
 
   }, [any_data, level_number]);
   return { all_steps, all_titles, loading };
}
 
export default GetData;
