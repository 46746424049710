import React from "react";
import './DownloadCard.css';

function DownloadCard(props) {

    return (
        <div className="download-card-wrapper">
            <a href={props.downloadLink} target="_blank" rel="noreferrer">
            <div className="download-card">

                <div className="download-image">
                    <img alt={`A preview of the download for the ${props.downloadTitle}`} src={`../../../../../download_images/${props.downloadSlug}-download-image.webp`}></img>
                    <div style={{'height':'100%', 'width':'53.48%'}}>
                        <div className="resource-tag"><p className="resource-tag-name">DOWNLOADS</p></div>
                        <div className="download-text">
                            <h4 className="resource-title">{props.downloadTitle}</h4>
                            <button className="btn-link"><span className="btn-label">Download</span></button>
                        </div>
                    </div>
                </div>

                


                {/* <div className="download-text-wrapper">
                    <div className="resource-tag"><p className="resource-tag-name">DOWNLOADS</p></div>
                    <div className="download-text">
                        <h4 className="resource-title">{props.downloadTitle}</h4>

                        <button className="btn-link"><span className="btn-label">Download</span></button>
                    </div>
                </div> */}

            </div>
            </a>
        </div>
    )
}

export default DownloadCard;