import React from "react";
import { useParams } from "react-router-dom";
import Step from "./Step";
import './Progress.css'

function Progress(props) {
    let headers = props.progress_titles;
    let current_page = props.current_step;
    let params = useParams();
    let level_number = params.levelNumber;
    let course = params.course;
    return (
        <div className="progress">
            {headers.map((header, index) => <Step key={index + 1} done={(current_page > index) ? ' step-active' : ''} name={header} link={`/${course}/level/${level_number}/step/${index + 1}`}/>)}
            <Step key={`stepdone`} done="" name="Finish!" link={`/${course}/level/${level_number}/step/${current_page}`}/>
        </div>
    )
}

export default Progress;
