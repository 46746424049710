import "./SurveyFooter.css"

function SurveyFooter(){
    return(
        <div className="feedback_survey">
            <div className="feedback_survey_text">
                <h3>Share your feedback</h3>
                <p>Let us know what you think and help us improve Mission Encodeable by filling in our short feedback survey.</p>
            </div>

            <a className="survey_button" href="https://rebrand.ly/mission-encodeable-feedback" target="_blank" rel="noreferrer"><button className="btn_orange_fill">Take the survey</button></a>
        </div>
    )
}

export default SurveyFooter;