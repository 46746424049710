import close_cross from "../../../images/components/Navbar/x.svg";
import "./VideoModal.css";

function VideoModal(props) {
    if (!props.showVideo){
        return null
    }
    return(
        <div className="modal" onClick={props.onClose}>
            <div className='video-container'>
                <img src={close_cross} className="close-button" onClick={props.onClose}></img>
                <div className="modal-content" onClick={e => e.stopPropagation}>
                        <iframe src={props.videoLink} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullscreen></iframe>
                    {/* <button onClick={props.onClose}>Close</button> */}
                </div>
            </div>
        </div>
    )
}

export default VideoModal;