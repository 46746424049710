import React from 'react';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import "./Error.css";

function Error() {
    
    let navigate = useNavigate();

    return(
        <div className="Error_box">

        <Helmet>
            <title>Page not found | Mission Encodeable</title>
        </Helmet>

            <h2>Oops! Nothing to see here...</h2>
            <p>We're sorry, the page you're looking for doesn't exist, has been deleted or moved.</p>
            <button onClick={() => {navigate(`/`);}} className="btn_orange_fill">Back to homepage</button>
        </div>
    )
}

export default Error;