import React from "react";
import { Helmet } from 'react-helmet';

import "./ChristmasHome.css";

import christmas_me_tag from "../images/pages/christmas/christmas_me_tag.svg";
import ChristmasProject from "../components/pages/christmas/ChristmasProject";
import christmas_about_image from "../images/pages/christmas/christmas_about_image.webp";
import christmas_header_4 from "../images/pages/christmas/christmas_header_4.svg";
import christmas_header_3 from "../images/pages/christmas/christmas_header_3.svg";
import christmas_header_2 from "../images/pages/christmas/christmas_header_2.svg";
import christmas_header_1 from "../images/pages/christmas/christmas_header_1.svg";

import twitter from "../images/pages/christmas/christmas_twitter_logo.svg";
import linkedin from "../images/pages/christmas/christmas_linkedin_logo.svg";
import facebook from "../images/pages/christmas/christmas_facebook_logo.svg";

import christmas_delivery_van from "../images/pages/christmas/christmas_delivery_van.svg";
import MailchimpFormContainer from "../components/universal/NewsletterFooter/MailchimpFormContainer";


function ChristmasHome() {
    return(
        <div className="christmas-home-bg">
            <Helmet>
                <title>Christmas Coding Challenges | Mission Encodeable</title>
                <meta name="description" content="Mission Encodeable's emporium of free, festive Python coding challenges." />
            </Helmet>

            <div className="christmas-header">
                <img className="christmas-header-1" src={christmas_header_1} alt='Christmas Coding Challenges header'></img>
                <img className="christmas-header-2"src={christmas_header_2} alt='Christmas Coding Challenges header'></img>
                <img className="christmas-header-3"src={christmas_header_3} alt='Christmas Coding Challenges header'></img>
                <img className="christmas-header-4"src={christmas_header_4} alt='Christmas Coding Challenges header'></img>
                <a href="/" target="_blank"><img src={christmas_me_tag} className="present-tag" alt=''></img></a>
            </div>

            <section className="christmas-top-about ">
                <div className="christmas-top-about-text">
                    <h1 className="christmas-white">Season's greetings!</h1>
                    <p className="christmas-white">Welcome to this emporium of free, festive Python coding challenges - brought to you by the creators of Mission Encodeable!</p>
                    <p className="christmas-white">Every project has a breakdown of steps, and some hints to ho-ho-help you on your way if you get stuck. Feel free to customise the projects to make them your own, and most importantly, have fun!</p>
                    <a href="/blog/unwrap-the-festive-fun-our-christmas-coding-challenges" target="_blank" rel="noreferrer" className="a-no-decoration"><button className="btn-christmas-blue-fill">Learn more</button></a>
                </div>
            </section>
            <section className="christmas-project-block christmas-project-block-red">
                <h2>Levels 1-3</h2>
                <p>The first three levels of the Mission Encodeable Python course cover: inputs, outputs, structured programs, selection and count-controlled iterations using while loops.</p>
                <div className="christmas-project-wrapper">
                    <ChristmasProject colour="red" level_number={1} project_name="Jingle Bells" project_slug="jingle-bells"/>
                    <ChristmasProject colour="red" level_number={2} project_name="Rudolph's adventure story" project_slug="rudolphs-adventure"/>
                    <ChristmasProject colour="red" level_number={3} project_name="Christmas tree generator" project_slug="christmas-tree-generator"/>
                </div>
            </section>
            <section className="christmas-project-block christmas-project-block-green">
                <h2>Levels 4-6</h2>
                <p>Levels 4-6 of the Mission Encodeable Python course cover: mathematical operations, more advanced string formatting, lists and count-controlled iteration using for loops.</p>
                <div className="christmas-project-wrapper">
                    <ChristmasProject colour="green" level_number={4} project_name="Christmas card creator" project_slug="christmas-card-creator"/>
                    <ChristmasProject colour="green" level_number={5} project_name="Elf name generator" project_slug="elf-name-generator"/>
                    <ChristmasProject colour="green" level_number={6} project_name="12 days of Christmas" project_slug="12-days-of-christmas"/>
                </div>
            </section>
            <section className="christmas-project-block christmas-project-block-blue">
                <h2>Levels 7-9</h2>
                <p>The final three levels of the Mission Encodeable Python course cover: string methods, file and exception handling and two dimensional lists.</p>
                <div className="christmas-project-wrapper">
                    <ChristmasProject colour="blue" level_number={7} project_name="Christmas hangman" project_slug="festive-hangman"/>
                    <ChristmasProject colour="blue" level_number={8} project_name="Advent calendar" project_slug="advent-calendar"/>
                    <ChristmasProject colour="blue" level_number={9} project_name="Christmas trivia quiz" project_slug="christmas-trivia-quiz"/>
                </div>
            </section>

            <section className="christmas-about-section">
                <div><img src={christmas_about_image} alt='Harry and Anna, the creators of Mission Encodeable.'></img></div>
                <div>
                    <h2>About us</h2>
                    <p>Welcome to this emporium of free, festive Python coding challenges. Here you'll find nine festively-themed projects and create this Christmas.</p>

                    <p>These challenges are brought to you by Anna and Harry, the creators of <a href="https://missionencodeable.com" target="_blank" className="christmas-link" rel="noreferrer">Mission Encodeable</a> — a free website that teaches young people to code in Python. There's one project which uses the skills covered in each level of the Mission Encodeable Python course, so you'll be sure to find something for you.</p>

                    <p>Every project tells learners what they need to do, and has some hints to ho-ho-help you on your way if you get stuck. Additionally, you'll find links to some of our helpful step-by-step Python tutorials, to help refresh your memory if you've forgotten how to do something. Feel free to customise the projects to make them your own, and most importantly, have fun!</p>

                    <p>Good luck, and Merry Christmas!</p>
                </div>

            </section>
            <section>
                <img src={christmas_delivery_van} className="christmas-newsletter-van" alt=''></img>
                <div className="christmas-newsletter">
                    <h2>Teachers: Sign up to our coding newsletter</h2> 
                    <MailchimpFormContainer/>
                </div>
            </section>

            <footer>
                <div className='second-row christmas-footer-second-row'>
                        <p className='white'>© Mission Encodeable 2023</p>
                        <div className="christmas-social-footer-icons social-footer-icons">
                            <a href="https://www.linkedin.com/company/mission-encodeable/" target="_blank" rel="noreferrer" className="social-link"><img src={linkedin} alt="LinkedIn icon"/></a>
                            <a href="https://twitter.com/missionencode" target="_blank" rel="noreferrer" className="social-link"><img src={twitter} alt="Twitter icon"/></a>
                            <a href="https://www.facebook.com/people/Mission-Encodeable/100094369925208/" target="_blank" rel="noreferrer" className="social-link"><img src={facebook} alt="Facebook icon"/></a>
                        </div>
                </div>
            </footer>
        </div>
    )
}

export default ChristmasHome;