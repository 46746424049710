import { useEffect, useState } from 'react';
 
function GetCourseInfo(course, level_number) {
   const [class_project_title, setClassProjectTitle] = useState([]);
   const [challenge_project_title, setChallengeProjectTitle] = useState([]);
   const [class_project_description, setClassProjectDescription] = useState([]);
   const [challenge_project_description, setChallengeProjectDescription] = useState([]);
   const [class_project_replit_link, setClassProjectReplitLink] = useState([]);
   const [challenge_project_replit_link, setChallengeProjectReplitLink] = useState([]);
   const [level_topics, setLevelTopics] = useState([]);
   const [level_description, setLevelDescription] = useState([]);
   const [video_link, setVideoLink] = useState([]);
 
   const [loading, setLoading] = useState(true);
   useEffect(() => {
       const host = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : '';
       const url = `${host}/api/${course}/introduction/${level_number}`;
       const fetchData = async () => {
           try {
           const response = await fetch(url);
           const json = await response.json();
           setClassProjectTitle(json[0].class_project_title)
           setChallengeProjectTitle(json[0].challenge_project_title)
           setClassProjectDescription(json[0].class_project_description)
           setChallengeProjectDescription(json[0].challenge_project_description)
           setClassProjectReplitLink(json[0].class_project_replit_link)
           setChallengeProjectReplitLink(json[0].challenge_project_replit_link)
           setLevelTopics(json[0].level_topics)
           setLevelDescription(json[0].level_description)
           setVideoLink(json[0].introduction_video_link)
 
           setLoading(false);
           } catch (error) {
               console.log("error", error);
               setLoading(false);
           }
       };
  
           fetchData();
 
   }, [level_number]);
   return { class_project_title, challenge_project_title, level_topics, class_project_description, challenge_project_description, class_project_replit_link, challenge_project_replit_link, level_description, video_link, loading};
}
 
export default GetCourseInfo;