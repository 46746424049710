import "./Privacy.css"
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';

function Privacy(){
    return(
        <div className="policy-wrapper">

        <Helmet>
            <title>Privacy policy | Mission Encodeable</title>
            <meta name="description" content="The Mission Encodeable privacy policy explains the what, how, and why of the information we collect when you visit our website." />
        </Helmet>

        <div className="privacy-fill-header">
            <div className="text-header">
                <h1 className="darkgreen">Privacy policy</h1>
                <p className="darkgreen">Thanks for visiting the Mission Encodeable website. This privacy notice explains the what, how, and why of the information we collect when you visit our website. It also explains the specific ways we use and disclose that information. We take your privacy extremely seriously, and we never sell lists or email addresses.</p>
            </div>
        </div>      

        <div className="policy-body">
        <h3>Notice to parents and guardians</h3>
            <p>We are committed to protecting the privacy of the young people that engage with us through our website.</p>
            <p>We treat your child’s information in accordance with all applicable laws concerning the protection of personal information.</p>

            <h3>Who we are</h3>
            <p>Mission Encodeable is a UK based platform that offers free courses in coding, design and digital making for children and young people across the world. </p>
            <p>Within the context of this policy, when we refer to ‘we’, ‘us’, or ‘our’, we’re referring to Mission Encodeable.</p>

            <h3>What information we collect from you</h3>
            <p><strong>Information you provide to us directly: </strong>When you visit our website, you might be asked to provide some personal data. For example, if you contact us. If you don’t want to provide us with personal data you don’t have to, but you may miss out on some of our services and resources.</p>
            <p><strong>Information we collect automatically: </strong>When you visit our website, some data is collected and stored automatically. For example your IP address and the device you are using and the time of your visit. We also keep a record of what web pages you have looked at and how long you have looked at them, so we have a better understanding of how you use our website and we can tailor our content to you. We may collect this information through the use of cookies or other tracking technologies.</p>
            <p><strong>Information we get from third parties: </strong>The majority of information we collect, we collect directly from you. Sometimes we might collect personal data about you from other sources, such as publicly available materials or trusted third parties like our marketing and research partners. We use this information to supplement the personal data we already hold about you, in order to better inform, personalise and improve our services, and to validate the personal data you provide.</p>

            <h3>How we use the information we collect</h3>
            <ul>
                <li>To send marketing materials relating to Mission Encodeable that discuss our services, or provide relevant and interesting content and resources to the computing industry. This includes sending occasional promotional emails.</li>
                <li>To improve our services, user experience and content</li>
                <li>To respond to your queries</li>
                <li>For internal business purposes</li>
            </ul>

            <h3>How we share your data</h3>
            <p>We do not share your data with third party marketers. There are times we may share your data with third parties to process your data on our behalf. We will only disclose your data to:</p>
            <ul>
                <li>Third-party service providers to process and store user data. We may provide these vendors with personal data, to carry out the services they are performing for us. Third-party analytics services providers such as Google Analytics may set and access their own Tracking Technologies on your Device and they may otherwise collect or have access to information about you, potentially including Personal Data, about you.</li>
                <li>We use Hotjar in order to better understand our users’ needs and to optimise this service and experience. Hotjar is a technology service that helps us better understand our users’ experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users’ behaviour and their devices. This includes a device's IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and the preferred language used to display our website. Hotjar stores this information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any of the data collected on our behalf. For further details, please see the ‘about Hotjar’ section of <a href="https://help.hotjar.com/hc/en-us/categories/115001323967-About-Hotjar">Hotjar’s support site.</a></li>
                <li>Regulators, law enforcement bodies, government agencies, courts or other third parties where we think it’s necessary to comply with applicable laws or regulations, or to exercise, establish or defend our legal rights.</li>
            </ul>

            <h3>How long we store your data</h3>
            <p>The length of time we keep your personal data depends on what it is and whether we have an ongoing need to retain it. We do not keep your information for any longer than we need to in order to fulfil the purpose or purposes for which it was collected, or for any longer than is required by law, if that is longer.</p>

            <h3>Anonymised data</h3>
            <p>By definition, anonymised data is data that does not contain any information that can be used to identify an individual.</p>
            <p>Once we have anonymised data we are lawfully allowed to retain it for as long as we need it, with no formal justification.</p>
            <p>Anonymised data may be useful for analytical or statistical insight. We are most likely to retain anonymised data for research, analysis, and impact evaluation.</p>

            <h3>Security</h3>
            <p>Security is a priority for us when it comes to your personal data. We’re committed to protecting your personal data and have appropriate technical and organisational measures in place to make sure that happens.</p>
            <p>You should be aware that the use of the internet is not entirely secure, and although we will do our best to protect your personal data, we cannot guarantee the security or integrity of any personal information that is transferred from you or to you via the internet. Any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features such as encryption to try to prevent unauthorised access.</p>
            <p>If a security breach causes an unauthorised intrusion into our system that materially affects you, then Mission Encodeable will notify you as soon as possible and later report the action we took in response.</p>
        
            <h3>Your rights</h3>
            <p>Under data protection law, you have rights including:</p>
            <ul>
                <li><strong>Your right of access</strong> - You have the right to ask us for copies of your personal information.</li>
                <li><strong>Your right to rectification</strong> - You have the right to ask us to rectify personal information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete. </li>
                <li><strong>Your right to erasure</strong> - You have the right to ask us to erase your personal information in certain circumstances. </li>
                <li><strong>Your right to restriction of processing</strong> - You have the right to ask us to restrict the processing of your personal information in certain circumstances. </li>
                <li><strong>Your right to object to processing</strong> - You have the right to object to the processing of your personal information in certain circumstances.</li>
                <li><strong>Your right to data portability</strong> - You have the right to ask that we transfer the personal information you gave us to another organisation, or to you, in certain circumstances.</li>
            </ul>

            <p>You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you. Please contact us via our <Link to="/contact">contact page</Link> if you wish to make a request.</p>

            <h3>On what legal basis we use your information</h3>
            <ul>
                <li>Where it is within our legitimate interests to process your information to further our mission to offer free courses to young people all around the world. We know that under this lawful basis for processing, we have a heightened responsibility to keep your interests central and to make sure that your rights as a data subject override our legitimate interests.</li>
                <li>Where we need to comply with a legal obligation.</li>
                <li>Where we have your consent to process your personal and special category data, where applicable.</li>
            </ul>

            <h3>Contacting us or making a complaint</h3>
            <p>If you are unhappy about the way we have processed your personal information for any reason, please <Link to="/contact">contact us</Link> in the first instance to see if we can resolve your complaint. If you are still unhappy, you can contact the Information Commissioner’s Office as the statutory body which oversees data protection law – <a href="https://ico.org.uk/concerns/handling/.">https://ico.org.uk/concerns/handling/.</a></p>

            <h3>Changes to this policy</h3>
            <p>We may update this privacy policy from time to time to ensure it remains fully accurate. You should check this page occasionally to ensure you are happy with any changes.</p>
            <p><i>Updated March 2022</i></p>
        </div>    
        </div>
    )
}

export default Privacy