import React from 'react';
import { useLocation } from "react-router-dom";

import GetAllDownloads from '../../../../hooks/getAllDownloads';
import DownloadCard from "../DownloadCard/DownloadCard";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import SwiperCore, { Navigation } from 'swiper';

import './DownloadCardSwiper.css';
SwiperCore.use([Navigation]);

function DownloadCardSwiper() {

    const { state } = useLocation();
    let { all_downloads } = GetAllDownloads(state); 

    return (
        <div className="download-card-swiper-container">
            <Swiper
                navigation
                spaceBetween={10}
                pagination={{
                    clickable: true,
                    renderBullet: function(index, className) {
                        return '<span class="' + className + '"><img class="pagination-bullet" alt=""/></span>';
                    }
                }}
                breakpoints={{
                    600: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    
                    1040: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    }
                }}
                modules={[Pagination]}
                className="downloadCardSwiper"
              >
                    {all_downloads.map((download) => 
                        <SwiperSlide>
                            <DownloadCard 
                                downloadTitle={download.title}
                                downloadSlug={download.urlSlug}
                                downloadLink={download.downloadLink}
                            />
                        </SwiperSlide>
                    )}
            
                </Swiper> 

        </div>  
    )
}

export default DownloadCardSwiper;