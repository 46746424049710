import { Helmet } from 'react-helmet';
import "./Blog.css";

import BlogCardSwiper from '../components/pages/resources/BlogCardSwiper/BlogCardSwiper.js';

function Resources(){

    return(
        <div>

            <Helmet>
                <title>Mission Encodeable Blog</title>
                <meta name="description" content="Discover the latest blog posts and articles from the Mission Encodeable team." />
            </Helmet>


            <div className="resources-header">
                <div className="resources-header-text">
                    <h1 className='resources-header-title'>Blog</h1>
                    <p className='resources-header-description'>Here you'll find the latest blog posts and articles from the Mission Encodeable team.</p>
                </div>
            </div>

            <section className='blog-posts' id="blog-posts">

                <h3 className="blog-posts-subheading">Latest articles</h3>

                <BlogCardSwiper />
                    
            </section>

        </div>
    )
}


export default Resources;