import { useEffect, useState } from 'react';

function GetAllLevelInfo(course) {

    const [all_level_card_information, setAllLevelCardInformation] = useState([]);

    const [loading, setLoading] = useState(true);
   
    useEffect(() => {
        const host = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : '';
        const url = `${host}/api/${course}/all-level-information`;
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const api_data = await response.json();

                let level_card_information = [];
                
                for (let i = 0; i < api_data.length; i++){
                    level_card_information.push({"class_project":api_data[i].class_project_title, 
                                                "challenge_project":api_data[i].challenge_project_title, 
                                                "level_description":api_data[i].level_description});

                }

                setAllLevelCardInformation(level_card_information);

                setLoading(false);

            } catch (error) {
                console.log("error", error);
                setLoading(false);
            }
        };

        fetchData();

    }, [course]);
    return { all_level_card_information, loading };
}

export default GetAllLevelInfo;