import "./BenefitsCard.css"

function BenefitsCard(props){
    return(
        <div className={`benefit_div ${props.type}`}>
            <img className="benefit_icon" src={props.benefitIcon} alt=""/>
            <h3 className="green">{props.benefitTitle}</h3>
            <p className="grey">{props.benefitDescription}</p>
        </div>
    )
}

export default BenefitsCard;