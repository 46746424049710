import React from "react";
import { Helmet } from 'react-helmet';

import "./SummerHome.css";

import summer_me_suitcase from "../images/pages/summer/summer_me_suitcase.svg";
import SummerProject from "../components/pages/summer/SummerProject";
import summer_about_image from "../images/pages/summer/summer_about_image.webp";
import summer_header_4 from "../images/pages/summer/summer_header_4.webp";
import summer_header_3 from "../images/pages/summer/summer_header_3.webp";
import summer_header_2 from "../images/pages/summer/summer_header_2.webp";
import summer_header_1 from "../images/pages/summer/summer_header_1.webp";

import twitter from "../images/pages/summer/summer_twitter_logo.svg";
import linkedin from "../images/pages/summer/summer_linkedin_logo.svg";
import facebook from "../images/pages/summer/summer_facebook_logo.svg";

import summer_campervan from "../images/pages/summer/summer_campervan.svg";
import MailchimpFormContainer from "../components/universal/NewsletterFooter/MailchimpFormContainer";


function SummerHome() {
    let year = new Date().getFullYear();
    return(
        <div className="summer-home-bg">
            <Helmet>
                <title>Summer Coding Challenges | Mission Encodeable</title>
                <meta name="description" content="Mission Encodeable's emporium of free, summer themed Python coding challenges." />
            </Helmet>

            <div className="summer-header">
                <img className="summer-header-1" src={summer_header_1} alt='Summer Coding Challenges header'></img>
                <img className="summer-header-2"src={summer_header_2} alt='Summer Coding Challenges header'></img>
                <img className="summer-header-3"src={summer_header_3} alt='Summer Coding Challenges header'></img>
                <img className="summer-header-4"src={summer_header_4} alt='Summer Coding Challenges header'></img>
                <a href="/" target="_blank"><img src={summer_me_suitcase} className="suitcase-tag" alt=''></img></a>
            </div>

            <section className="summer-top-about">
                <div className="summer-top-about-text">
                    <h1 className="summer-yellow">Happy Summer!</h1>
                    <p className="christmas-white">Welcome to this emporium of free, summer themed Python coding challenges - brought to you by the creators of Mission Encodeable!</p>
                    <p className="christmas-white">Every project has some instructions to follow, and some hints to help you if you get stuck. Feel free to customise the projects to make them your own, and most importantly, have fun!</p>
                </div>
            </section>

            <section className="summer-project-block">
                <h2 className="summer-blue">Levels 1-3</h2>
                <p className="summer-blue">The first three levels of the Mission Encodeable Python course cover: inputs, outputs, structured programs, selection and count-controlled iterations using while loops.</p>
                <div className="christmas-project-wrapper">
                    <SummerProject level_number={1} project_name="Campfire story generator" project_slug="campfire-story-generator"/>
                    <SummerProject level_number={2} project_name="Which sea creature are you?" project_slug="which-sea-creature-are-you"/>
                    <SummerProject level_number={3} project_name="Sandcastle smash" project_slug="sandcastle-smash"/>
                </div>
            </section>

            <section className="summer-project-block">
                <h2 className="summer-blue">Levels 4-6</h2>
                <p className="summer-blue">Levels 4-6 of the Mission Encodeable Python course cover: mathematical operations, more advanced string formatting, lists and count-controlled iteration using for loops.</p>
                <div className="summer-project-wrapper">
                    <SummerProject level_number={4} project_name="Sun, sand, sea" project_slug="sun-sand-sea"/>
                    <SummerProject level_number={5} project_name="Ice cream name generator" project_slug="ice-cream-name-generator"/>
                    <SummerProject level_number={6} project_name="Travel destination determiner" project_slug="travel-destination-determiner"/>
                </div>
            </section>
            <section className="summer-project-block">
                <h2 className="summer-blue">Levels 7-10</h2>
                <p className="summer-blue">The final four levels of the Mission Encodeable Python course cover: string methods, file and exception handling, two dimensional lists and the Python Turtle module.</p>
                <div className="summer-project-wrapper">
                    <SummerProject level_number={7} project_name="Postcard creator" project_slug="postcard-creator"/>
                    <SummerProject level_number={8} project_name="Summer songs" project_slug="summer-songs"/>
                    <SummerProject level_number={9} project_name="Coconut bowling" project_slug="coconut-bowling"/>
                    <SummerProject level_number={10} project_name="Ice cream creator" project_slug="ice-cream-creator"/>
                </div>
            </section>

            <section className="summer-about-section">
                <div><img src={summer_about_image} alt='Harry and Anna, the creators of Mission Encodeable.'></img></div>
                <div>
                    <h2>About us</h2>
                    <p>Welcome to this emporium of free, summer themed Python coding challenges!</p>
                    <p>These challenges are brought to you by Harry and Anna, the creators of <a href="/" className="summer-link">Mission Encodeable</a> — a free website that teaches young people to code in Python. There's one project which uses the skills covered in each level of the Mission Encodeable Python course, so you'll be sure to find something for you.</p>
                    <p>Every project has some instructions to follow, and some hints to help you if you get stuck Additionally, you'll find links to some of our helpful step-by-step Python tutorials, to help refresh your memory if you've forgotten how to do something. Feel free to customise the projects to make them your own, and most importantly, have fun!</p>
                </div>

            </section>
            <section>
                <img src={summer_campervan} className="summer-newsletter-van" alt=''></img>
                <div className="summer-newsletter">
                    <h2>Teachers: Sign up to our coding newsletter</h2> 
                    <MailchimpFormContainer/>
                </div>
            </section>

            <footer>
                <div className='second-row summer-footer-second-row'>
                        <p className='white'>© Mission Encodeable {year}</p>
                        <div className="summer-social-footer-icons social-footer-icons">
                            <a href="https://www.linkedin.com/company/mission-encodeable/" target="_blank" rel="noreferrer" className="social-link"><img src={linkedin} alt="LinkedIn icon"/></a>
                            <a href="https://twitter.com/missionencode" target="_blank" rel="noreferrer" className="social-link"><img src={twitter} alt="Twitter icon"/></a>
                            <a href="https://www.facebook.com/people/Mission-Encodeable/100094369925208/" target="_blank" rel="noreferrer" className="social-link"><img src={facebook} alt="Facebook icon"/></a>
                        </div>
                </div>
            </footer>
        </div>
    )
}

export default SummerHome;