import React from 'react'

import "./FeaturedLogos.css";

import craig_n_dave from "../../../images/components/FeaturedLogos/craig_n_dave.svg";
import first_news from "../../../images/components/FeaturedLogos/first_news.svg";
import hello_world from "../../../images/components/FeaturedLogos/hello_world.svg";
import learning_dust from "../../../images/components/FeaturedLogos/learning_dust.svg";
import pearson from "../../../images/components/FeaturedLogos/pearson.svg";
import real_python from "../../../images/components/FeaturedLogos/real_python.svg";
import cas from "../../../images/components/FeaturedLogos/cas.svg";
import teaching_python from "../../../images/components/FeaturedLogos/teaching_python.svg";

function FeaturedLogos(){
    return(
      	<div className="featured-logos">
			<div className="featured-logo-container">
				<a href="https://www.youtube.com/watch?v=uXj-6WQ8s2U" target="_blank" rel="noreferrer"><img src={craig_n_dave} alt="Craig 'N' Dave" loading="lazy" /></a>
			</div>

			<div className="featured-logo-container">
				<img src={first_news} alt="First News" loading="lazy" />
			</div>

			<div className="featured-logo-container">
				<a href="https://helloworld.raspberrypi.org/articles/hw19-introducing-mission-encodeable" target="_blank" rel="noreferrer"><img src={hello_world} alt="Hello World" loading="lazy" /></a>
			</div>

			<div className="featured-logo-container">
				<a href="https://www.learningdust.com/home/the-vault/podcast#h.5xd4pfn6q0k7" target="_blank" rel="noreferrer"><img src={learning_dust} alt="Learning_Dust" loading="lazy" /></a>
			</div>

			<div className="featured-logo-container">
				<a href="https://qualifications.pearson.com/content/dam/pdf/GCSE/Computer%20Science/2020/CS-2020-Marketing/gcse-computerscience-harrywake-casestudy.pdf" target="_blank" rel="noreferrer"><img src={pearson} alt="Pearson" loading="lazy" /></a>
			</div>

			<div className="featured-logo-container">
				<a href="https://realpython.com/podcasts/rpp/120/" target="_blank" rel="noreferrer"><img src={real_python} alt="Real Python" loading="lazy" /></a>
			</div>

			<div className="featured-logo-container">
				<a href="https://www.computingatschool.org.uk/events/2024/april/mission-encodeables-free-programming-resources" target="_blank" rel="noreferrer"><img src={cas} alt="CAS" loading="lazy" /></a>
			</div>

			<div className="featured-logo-container">
				<a href="https://www.teachingpython.fm/93" target="_blank" rel="noreferrer"><img src={teaching_python} alt="Teaching Python" loading="lazy" /></a>
			</div>
      </div>
    )
}

export default FeaturedLogos;
