import "./NewsletterFooter.css"
import MailchimpFormContainer from "./MailchimpFormContainer";

function NewsletterFooter(){
    return(
        <div className="newsletter_footer">
            <div className="newsletter-footer-content">

                <div className="newsletter-subscribe-text-wrapper">
                    <h3 className="newsletter-subscribe-headline">Teachers: Sign up to our <em>coding</em> newsletter</h3>
                </div>
                
                <div className="newsletter-subscribe-form-wrapper">
                    <MailchimpFormContainer/>
                </div>

            </div>
        </div>
    )
}

export default NewsletterFooter;