import hljs from 'highlight.js/lib/core';
import python from 'highlight.js/lib/languages/python';
import 'highlight.js/styles/atom-one-light.css';

hljs.registerLanguage('python', python);
// Assuming you are highlighting all `pre > code` blocks on your page
function HighlightCode() {

    function isHLJSEl(el) {
        return el.classList.contains('hljs');
    }

    function isHighlighted(el) {
        return el.classList.contains('hljs-highlighted');
    }

    // Using the function
    document.querySelectorAll('pre > code').forEach((el) => {
        if (isHLJSEl(el) && !isHighlighted(el)) {
            hljs.highlightElement(el);
            el.classList.add('hljs-highlighted');
        }
    });

}

export default HighlightCode;