import {useEffect, useState} from "react";

function GetSeasonalProject(level_number, season) {
    const [title, setTitle] = useState([]);
    const [content_html, setContent] = useState([]);
    const [colour, setColour] = useState([]);

    useEffect(() => {
        const host = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : '';
        const url = `${host}/api/${season}/python/level/${level_number}`;
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                setContent(json[0].content_html)
                setTitle(json[0].title)
                setColour(json[0].colour)
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchData();
    })
    return { title, content_html, colour };
}
export default GetSeasonalProject;