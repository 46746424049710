import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import './Teachers.css';


import teachers_hero from '../images/pages/teacher_page/hero_climber_image.png';
import reduce_costs_icon from "../images/universal/icons/reduce_costs_icon.svg";
import uplevel_engagement_icon from "../images/universal/icons/uplevel_icon.svg"
import save_time_icon from "../images/universal/icons/save_time_icon.svg";
import cnd_stacked_logo from "../images/pages/teacher_page/stacked_logo_cnd.svg";
import cnd_long_logo from "../images/pages/teacher_page/long_logo_cnd.svg";
import brushstroke from "../images/universal/annotations/brushstroke.svg";


import BenefitsCard from '../components/universal/BenefitsCard/BenefitsCard';
import GeneralResourceCard from "../components/pages/teacher_page/GeneralResource";
// import LevelResourceCard from "../components/pages/teacher_page/LevelResource";
// import Loading from "./Loading";
import FeaturedLogos from "../components/universal/FeaturedLogos/FeaturedLogos";
import NewsletterFooter from "../components/universal/NewsletterFooter/NewsletterFooter";
import BlogCardSwiper from "../components/pages/resources/BlogCardSwiper/BlogCardSwiper.js";




function Teachers() {


    // const [levelNumber, setLevelNumber] = useState(1)
    // const { state } = useLocation();
    // let { all_teacher_handbooks, all_student_handbooks, all_solution_packs, loading } = GetLevelResources(state);
    // console.log('ATH = ', all_teacher_handbooks)

    return (
        <div>
            <Helmet>
                <title>Mission Encodeable for teachers</title>
                <meta name="description" content="Helpful resources for teachers." />
            </Helmet>
            <div className="powder_blue_bg">
                <section className='teacher-hero'>
                    <div className='text'>
                        <h1 className="darkgreen">Everything you need to teach coding with <em>confidence</em></h1>
                        <p className="darkgreen">Check out our comprehensively crafted, free resources designed to accompany the Mission Encodeable course.</p>
                    </div>
                    <div className="hero-image-wrapper">
                        <img src={teachers_hero} className="hero_img" alt="A close up of a climber" />
                    </div>
                </section>
                <Link to="/craig-n-dave-partnership" className="a-no-decoration">
                    <div className="cnd-partnership">
                        <div className="cnd-partnership-brushstroke">
                            <img src={brushstroke}></img>
                        </div>
                        <div className="cnd-partnership-logo-text">
                            <p>In partnership with:</p>
                            <img src={cnd_long_logo}></img>
                        </div>
                    </div>
                </Link>
                <section className="resources-introduction">
                    <div className="resources-introduction-text">
                        <p className="mini-section-heading mini-secton-heading-centred">Resources</p>
                        <h3 className='darkgreen engaging-tutorials-benefit-padded'>Created with <u>teachers</u> in mind</h3>
                        <p className="darkgreen resources-about-paragraph">Mission Encodeable's tutorials promote independent learning through hands-on practice, empowering students to become confident and proficient coders. <br /><br /> Our accompanying resources for teachers have been built from the ground up to help you save time and uplevel student engagement.</p>
                    </div>
                    {/* <div className='quick-scroll'>
                        <p className="mini-section-heading">Quick scroll to:</p>
                        <div className="resource-types">
                            <a className="darkgreen resource-type-heading" href="#teaching-guides">Teaching guides</a>
                            <a className="darkgreen resource-type-heading" href="#classroom-materials">Classroom materials</a>
                            <a className="darkgreen resource-type-heading" href="#level-specific-resources">Level specific resources</a>
                        </div>
                    </div> */}
                </section>
            </div>
            <div className="white-bg">
                {/* <section className="teacher-resource-section" id="teaching-guides">
                    <h3 className="darkgreen">Teaching <u>guides</u></h3>
                    <p className="green">Our comprehensive teaching guides are packed with pedagogical strategies and step-by-step instructions.</p>
                    <div className="teacher-resource-inline-block">
                        <GeneralResourceCard name="Getting Started Guide" date_published="26 July 2023" file_type="PDF" file_size="10.0 MB" description="An introduction to Mission Encodeable, Lorem Ipsum sit idor." download_link="https://1drv.ms/f/s!AqDUwo2EhNFibb4OeVw5FLf2asc" />
                        <GeneralResourceCard name="Getting Started Guide" date_published="26 July 2023" file_type="PDF" file_size="10.0 MB" description="An introduction to Mission Encodeable, Lorem Ipsum sit idor." download_link="https://1drv.ms/f/s!AqDUwo2EhNFibb4OeVw5FLf2asc" />
                    </div>
                </section> */}
                <section className="teacher-resource-section" id="classroom-materials">
                    <h3 className="darkgreen">Classroom <u>materials</u></h3>
                    <p className="green">Useful guides and resources for use around school and in the classroom.</p>
                    <div className="teacher-resource-inline-block">
                        <GeneralResourceCard name="Coding Notebook Sheets" date_published="10 August 2023" file_type="PDF" file_size="252 KB" description="A printable A4 note sheet that students can use to document their learning." download_link="https://rebrand.ly/re079bl" link_action_type="Download"/>
                        <GeneralResourceCard name="Student Progress Tracker" date_published="22 October 2023" file_type="XLS" file_size="13.0 KB" description="A spreadsheet you can use to track your students' progress through our course." download_link="https://rebrand.ly/f1by6kw" link_action_type="Download"/>
                        <GeneralResourceCard name="Launch Presentation" date_published="22 October 2023" file_type="PPT" file_size="13.2 MB" description="A presentation to introduce students to Mission Encodeable. " download_link="https://rebrand.ly/xqsr2qy" link_action_type="Download" />
                        <GeneralResourceCard name="Python Solution Code Files" date_published="25 February 2024" file_type="PY" file_size="69.2 KB" description="The solution code to all programs in our Python course. This is only available to teachers." download_link="https://rebrand.ly/ME-request-solution-files" link_action_type="Request access"/>
                    </div>
                </section>

                <section className="teacher-resource-section" id="classroom-materials">
                    <h3 className="darkgreen">Promotional <u>materials</u></h3>
                    <p className="green">Materials to help you promote Mission Encodeable within your organisation.</p>
                    <div className="teacher-resource-inline-block">
                        <GeneralResourceCard name="Promotional Poster Set" date_published="10 August 2023" file_type="PDF" file_size="26.7 MB" description="Three high-quality posters to download and display in your classroom." download_link="https://rebrand.ly/dm6re5d" link_action_type="Download"/>
                        <GeneralResourceCard name="Computing Legends Poster Set" date_published="01 January 2024" file_type="PDF" file_size="5.46 MB" description="A series of posters to celebrate some of the key pioneers in computing history." download_link="/legends" link_action_type="View the series"/>
                    </div>
                </section>
                {/* <section className="teacher-resource-section" id="level-specific-resources">
                    <h3 className="darkgreen"><u>Level-specific</u> resources</h3>
                    <p className="green">Specifically designed handbooks and solution packs to accompany each level.</p>
                    <div className="level-selector-block">
                        <p className="green">Level:</p>
                        <div>
                            <button className={levelNumber === 1 ? 'level-pill-active' : 'level-pill'} onClick={() => setLevelNumber(1)}>Python Level 1</button>
                            <button className={levelNumber === 2 ? 'level-pill-active' : 'level-pill'} onClick={() => setLevelNumber(2)}>Python Level 2</button>
                            <button className={levelNumber === 3 ? 'level-pill-active' : 'level-pill'} onClick={() => setLevelNumber(3)}>Python Level 3</button>
                        </div>
                    </div>
                    {/* <div>
                        <LevelResourceCard name="Teacher Handbook" level_number={levelNumber} file_size={23} date_published={06.08.23} download_link={all_teacher_handbooks[levelNumber - 1].download_link} />
                        <LevelResourceCard name="Student Handbook" level_number={levelNumber} file_size={42]} date_published={all_student_handbooks[levelNumber - 1].date_published} download_link={all_student_handbooks[levelNumber - 1].download_link} />
                        <LevelResourceCard name="Solution Code Files" level_number={levelNumber} file_size={21]} date_published={all_solution_packs[levelNumber - 1].date_published} download_link={all_solution_packs[levelNumber - 1].download_link} />
                    </div> */}
                {/* </section> */}


                <div className="teacher-benefits-wrapper">
                    <section className="teacher-benefits">
                            <p className="mini-section-heading mini-secton-heading-centred">The Benefits</p>
                            <h3 className='darkgreen engaging-tutorials-benefit-padded'>Coding tutorials you can <em>count on</em></h3>
                            <p className="darkgreen">Our tutorials take a creative, project-based approach, that will engage students as they work independently. We cover the entire programming content of the Computer Science GCSE, so you can be sure that your students are capable of achieving the very highest grades.</p>
                        <div className="benefits-wrapper teacher-benefits-wrapper">
                            <BenefitsCard
                                benefitIcon={save_time_icon}
                                benefitTitle="Save time"
                                benefitDescription="Free up your planning time and take control of your workload."
                                type="no-bg"
                            />


                            <BenefitsCard
                                benefitIcon={uplevel_engagement_icon}
                                benefitTitle="Uplevel engagement"
                                benefitDescription="Mission Encodeable offers a focus on practical coding exercises and projects."
                                type="no-bg"
                            />


                            <BenefitsCard
                                benefitIcon={reduce_costs_icon}
                                benefitTitle="Reduce costs"
                                benefitDescription="Our resources are all freely available, so there's no need to worry about budget."
                                type="no-bg"
                            />
                        </div>
                    </section>
                    </div>

                    <section className="teachers-featured-in featured-in">
                        <p className="mini-section-heading">Why trust us?</p>
                        <h3 className="featured-heading-white">We've been <u>featured</u> by industry experts & press</h3>
                        <FeaturedLogos />
                    </section>

                    <section className="cnd-info">
                        <img src={cnd_stacked_logo}></img>
                        <div>
                            <h3 className="darkgreen">Better together: Craig 'n' Dave Partnership</h3>
                            <p>We've created our resources in partnership with Craig 'n' Dave, leading practitioners of pedagogy in the subject of Computer Science. They have 25 years of experience, and they've done a lot of reading and soul searching over the years about how to teach programming effectively. We've redeveloped our scheme to offer seamless integration with their new TIME 2 CODE resource.</p>
                            <Link to="/craig-n-dave-partnership" className="a-no-decoration"><button className="btn_green_fill">Find out more</button></Link>
                        </div>
                    </section>

                    <div className="homepage-bottom-bg">

                        <div className="demo-cta">
                            <h3 className="darkgreen">If you're ready to transform the way your students learn to code, then we'd love to show you Mission Encodeable in action.</h3>
                            <Link to="/request-a-demo"><button className='btn_green_outline'>Request a demo</button></Link>
                        </div>

                        <hr className="block-seperator-green" />

                        <section className="home-resources">
                            <p className="mini-section-heading mini-section-heading-centred">Blog</p>
                            <h3 className="resources-section-intro"><u>Discover the latest</u> blog posts from the Mission Encodeable team</h3>

                            <section className='home-blog-posts' id="blog-posts">
                                <BlogCardSwiper />
                            </section>


                        </section>

                    </div>

                    <NewsletterFooter />
            </div>
        </div>
    )
}
export default Teachers;