import { useEffect, useState } from 'react';

function GetData(loaded_data) {

    const [all_posts, setAllPosts] = useState([]);

    useEffect(() => {
        if ( loaded_data === null  ) {
            const host = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : '';
            const url = `${host}/api/blog`;

            const fetchData = async () => {
                try {
                    
                    const response = await fetch(url);
                    const api_data = await response.json();

                    let posts = []
                    for (let i = 0; i < api_data.length; i++){
                        posts.push({'title' : api_data[i].title, 'date' : api_data[i].date, 'urlSlug' : api_data[i].url_slug, 'altText' : api_data[i].image_alt_text})
                    }

                    setAllPosts(posts)

                } catch (error) {
                    console.log("error", error);
                }

            };
    
            fetchData();
        } else {
            setAllPosts(loaded_data.posts)
        }

    }, [loaded_data]);
    return { all_posts };
}

export default GetData;