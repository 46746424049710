import React from 'react';
import { useState } from 'react';
import './ProgressAccordion.css';
import Progress from './Progress.js';
import { ReactComponent as PlusIcon } from '../../../images/universal/icons/plus_icon.svg';
import { ReactComponent as MinusIcon } from '../../../images/universal/icons/minus_icon.svg';

function ProgressAccordion(props) {
    const [isActive, setIsActive] = useState(false);
    
    return (
        <div>
            <div className="accordion">
                <div className="accordion-item">
                    <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                        <div><h5 className='darkgreen'>Contents</h5></div>
                        {isActive ? <MinusIcon fill="#00525e" className="accordion-image"/> : <PlusIcon fill="#00525e" className="accordion-image"/>}
                    </div>
                {isActive && <div className="accordion-content"><Progress progress_titles={props.all_titles} current_step={props.current_step}/></div>}
                </div>
            </div>
        </div>
      );
}

export default ProgressAccordion;