import React from "react";
import { useNavigate } from "react-router-dom";
import './Step.css'

function Step(props) {
  let navigate = useNavigate();
    return(
        <div className={`step ${props.done}`}>
        <div>
          <div className="circle" onClick={() => {navigate(props.link);}}></div>
        </div>
        <div>
            <div className="title" onClick={() => {navigate(props.link);}}>{props.name}</div>
        </div>
      </div>
    )
}

export default Step;